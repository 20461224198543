import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import LongshotImg from "../images/layout/longshot.png"
import { LanguageContext } from "../context/languageContextProvider"
import FourPointsVideo from "../images/four-points.mp4"

const IndexPage = () => {
  const [language, setLanguage] = React.useContext(LanguageContext)

  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <HomeInfo>
        <h2>What We Do</h2>
        {language === "EN"
          ? "OL The One Wedding Plaza is one of the largest, most powerful, and most reputable, and honest comprehensive wedding and business project co-financing entertainment platform company! We have made many couples dreams come true! We have been constantly innovating wonderful stage designs and innovative wedding dress updates! Let the brides rejoice!"
          : "欧陆经典婚庆娱乐是唯一一家最大最有实力最有口碑诚信为主经营的综合性婚礼和商业项目共筹娱乐平台公司！我们曾经让许多情侣梦想成真！ 我们一直在不断创新精彩的舞台设计与创新婚纱礼服更新！让新娘们满怀欣喜！"}
        <br></br>
        <br></br>
        {language === "EN"
          ? " Our event services include: stage props design; stage lighting; live DJ; hosting in Chinese, English, Cantonese, Malay, Shanghainese, Hokkien, Fuzhou; singing; dancing; magic performances; acrobatics; catwalks; traditional dances; etc."
          : "我们的活动服务包括：舞台道具设计、舞台灯光、现场DJ、主持人：中文、英文、粤语、马来西亚语、上海话、福建话、福州话、歌手、舞蹈、魔术、杂技、狂舞、走秀、古舞等 ."}
        <br></br>
        <br></br>
        {language === "EN"
          ? "We also provide top hair stylists, top makeup artists and beautiful outfits for brides, grooms and all their loved ones to ensure everyone looks their best on the big day! Our job is to make sure your special day is as wonderful as possible!"
          : "  我们还为新娘、新郎和他们所有的亲人提供顶级发型师、顶级化妆师和漂亮的服装，以确保每个人在大喜之日都看起来最好！ 我们的工作是确保您的特殊日子尽可能精彩！"}
        <br></br>
        <br></br>
        {language === "EN"
          ? " Check out our one stop wedding packages"
          : " 查看我们的一站式婚礼套餐"}
        <br></br>
        <br></br>
        <LearnBtn to="/about">Learn More</LearnBtn>
      </HomeInfo>
      <HomeVideo>
        <video autoPlay loop muted>
          <source
            src="https://res.cloudinary.com/diw3segy3/video/upload/v1652873238/videos/misc/9975_wf1pss.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </HomeVideo>
      <Quote>Just say I DO and let us do the rest</Quote>
      <HomeImages>
        <ImageOne
          src="https://res.cloudinary.com/diw3segy3/image/upload/v1596086863/couples/couple-54v_cuujxr.jpg"
          width="600"
          height="400"
          alt="Photo of wedding stage set"
        />
        <ImageTwo
          src="https://res.cloudinary.com/diw3segy3/image/upload/v1596086866/couples/couple-61v_equvoe.jpg"
          width="600"
          height="400"
          alt="Photo of wedding stage set"
        />
        <ImageThree
          src="https://res.cloudinary.com/diw3segy3/image/upload/v1596086854/couples/couple-34v_spv30d.jpg"
          width="600"
          height="400"
          alt="Photo of wedding stage set"
        ></ImageThree>
      </HomeImages>
      <Hotel>
        <h2>Need a book a hotel?</h2>
        <p>Book with Four Points by Sheraton Flushing!</p>
        <a href="https://www.marriott.com/en-us/hotels/nycfl-four-points-by-sheraton-flushing/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0">
          Click to Visit Four Points by Sheraton Flushing
        </a>
        <video autoPlay loop muted>
          <source src={FourPointsVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Hotel>
      <Testimonials>
        <h3>Testimonials</h3>
        <Testimonial>
          <p>
            "One of my greatest fears for this night was people just sitting at
            the table and silently watching, but Leo's energy, his experiences
            and the skills of his team, got people out of their chairs and
            participating in the festivities and games... Thank you so much for
            making our night memorable and wish many great events head your
            way!"
          </p>
          <p>- Doris Liu</p>
        </Testimonial>
        <Testimonial>
          <p>
            "Leo just hosted my wedding last Saturday. He made it a great party
            for everyone, we just loved it... Leo, great job, thank you and your
            crew for the memorable party, you are the best!"
          </p>
          <p>- Mimi Xu</p>
        </Testimonial>
        <Testimonial>
          <p>
            "Emcee Leo's professional services were astounding! He REALLY cares
            about your wedding, he is very experienced and gives a massive sense
            of peace in the flow of your wedding... If we ever have another
            event that requires emcee services or other related services such as
            DJ, photographers, makeup and hair, photo booth etc, I will not
            hesitate to seek emcee Leo for help again!"
          </p>
          <p>- Fui Jinn</p>
        </Testimonial>
      </Testimonials>

      <Longshot src={LongshotImg}></Longshot>
    </Layout>
  )
}

export default IndexPage

const HomeInfo = styled.div`
  width: 100%;
  padding: 3rem 15vw;

  h2 {
    font-family: "Noto Serif Display", serif;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    padding: 3rem 12vw;
  }
`

const Quote = styled.h3`
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 2rem;
  font-family: "Courgette", cursive;
`

const LearnBtn = styled(Link)`
  text-decoration: none;
  color: white;
  background: #3e454d;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;

  :hover {
    opacity: 0.8;
  }
`

const HomeImages = styled.div`
  width: 100%;
  height: 75vw;
  max-height: 1100px;
  position: relative;
  margin: 2rem 0;
`

const HomeVideo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  margin-top: 2rem;

  video {
    width: 720px;
    height: auto;
    max-width: 90vw;
    max-height: 52.99vw;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
`

const ImageOne = styled.img`
  position: absolute;
  top: 0;
  left: 20%;
  height: auto;
  max-width: 40vw;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media screen and (max-width: 768px) {
    width: 300px;
  }
`

const ImageTwo = styled.img`
  position: absolute;
  top: 25%;
  left: 45%;
  height: auto;
  max-width: 40vw;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media screen and (max-width: 768px) {
    width: 300px;
  }
`

const ImageThree = styled.img`
  position: absolute;
  top: 58%;
  left: 30%;
  height: auto;
  max-width: 40vw;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  @media screen and (max-width: 768px) {
    width: 300px;
  }
`

const Hotel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;

  h2 {
    font-family: "Noto Serif Display", serif;
    font-weight: 700;
  }

  video {
    width: 1200px;
    max-width: 90vw;
    border-radius: 6px;
    margin: 1.5rem 0;
  }

  a {
    text-decoration: none;
    transition: all 0.2s ease;
    color: black;
    font-weight: bold;

    &:hover {
      color: #b4d6d4;
    }
  }
`

const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15vw 7.5vw 15vw;
  background: #f2f6f5;
  padding: 2rem;

  h3 {
    font-family: "Noto Serif Display", serif;
    font-weight: 700;
  }

  @media screen and (max-width: 768px) {
    margin: 0 12vw 7.5vw 12vw;
  }
`

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  p {
    white-space: normal !important;
  }
`

const Longshot = styled.img`
  width: 100vw;
  height: auto;
  margin: 0;
`
